<template>
   <div class="makeCon">
      <el-dialog
        title="录制答案"
        :visible.sync="show"
        width="593px"
        @close="close"
        :close-on-click-modal="false"
        >
        <div class="popDialog">
            <div class="shijian">
              <u-count-down ref="countDown" :time="time * 1000" format="mm:ss"></u-count-down>
            </div>
            <div class="midaudio" v-if="status==-1">
              <img src="../assets/image/luyin.png" alt="" class="audioimg" @click="myRecording()">
              <div>点击『按钮』开始录制答案</div>
            </div>
            <div class="midaudio" v-if="status==0">
              <img src="../assets/image/stap.png" alt="" class="audioimg" @click="stopRecord()">
              <div class="font-22 text-bold">{{timeFormat(currentTime)}}</div>
            </div>
            <div class="midaudio" v-if="status==1">
              <img src="../assets/image/end.png" alt="" class="audioimg">
              <div class="m-b-20">点击『按钮』检查录制的答案</div>
              <div class="flex-y-center color-title2" @click="againRecord()">
                <img src="../assets/image/refresh.png" alt="" class="refresh">
                重新录制
              </div>
            </div>
            <div class="btnzu flex-x-center">
              <div @click="submit()">提交</div>
            </div>
        </div>
      </el-dialog>
   </div>
</template>
<script>
import Config from '@/api/config.js'
import recording  from '@/util/recorder.js'
import uCountDown from './u-count-down.vue'
export default {
  components: { uCountDown },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    time: {
        type:Number,
        default: 0,
    },
  },
   data() {
      return {
        status: -1,   // 录音进度-1等待 开始0 暂停1 
        interval: null,
        currentTime: 0,
        voicePath: '',
        recorder: null,
      }
   },
   destroyed() {
    this.clearTimer()
   },
   methods: {
      close() {
        this.clearTimer();
        this.currentTime = 0;
        this.status = -1;
        this.voicePath = "";
        if(this.recorder) this.recorder.stop()
        this.$refs.countDown.reset();
        this.$emit("close")
      },
      timeFormat: function (time) {
          if (!time) return '00:00'
          let min = parseInt(time / 60);
          let sec = parseInt(time) % 60;
          if (min < 10) min = '0' + min
          if (sec < 10) sec = '0' + sec
          return (min + ':' + sec)
      },
      //提交
      submit() {
        if(this.status == -1) {
          this.$message({
              message: '请输入答案',
              type: 'warning'
          });
          return
        }else {
          if(!this.voicePath) {
            this.$message({
              message: '请先停止录制',
              type: 'warning'
            });
            return
          }
          this.uploadVoice()
        }
      },
      uploadVoice() {
        console.log("上传文件")
        var that = this;
        this.$api.uploadAudioFile(Config.baseUrl + '/index/upload',{
          file: this.voicePath
        }).then(res => {
          console.log(res)
          if(res.code == 1) {
            var voiceUrl = res.data.url;
            // console.log("voiceUrl",voiceUrl)
            that.$emit("confirm",{
              voiceUrl: voiceUrl
            })
          }
        })
      },
      //重新录制
      againRecord() {
        this.clearTimer()
        this.status = -1;
        this.currentTime = 0;
        this.voicePath = "";
        this.$refs.countDown.reset();
        if(this.recorder) this.recorder.stop()
        this.myRecording()
      },
      // 点击录制
      myRecording() {
        //开始录音
        this.startRecord()
      },
      // 删除语音
      delvioce() {
        this.fileurl = "";
        this.files = "";
      },
      // 清除定时器
      clearTimer() {
        if (this.interval) {
          this.currentTime = 0;
          clearInterval(this.interval);
        }
      },
      // 开始录制
      startRecord() {
        this.clearTimer();
        recording.get((rec) => {
          // 当首次按下时，要获取浏览器的麦克风权限，所以这时要做一个判断处理
          if (rec) {
            this.status = 0;
            this.recorder = rec;
            this.interval = setInterval(() => {
                this.recorder.start();
                this.currentTime++;
            }, 1000);
          }
        });
      },
      // 停止录制
      stopRecord() {
        this.clearTimer();
        if (this.recorder) {
          this.recorder.stop(); // 重置说话时间
          this.status = 1;
          let bold = this.recorder.getBlob(); // 将获取的二进制对象转为二进制文件流
          let files = new File([bold], "test.mp3", {
            type: "audio/mp3",
            lastModified: Date.now(),
          });
          console.log("files",files);
          this.voicePath = files;
          //上传文件
         

          // this.files = files;
          //获取音频时长
          // let fileurl = URL.createObjectURL(files);
          // console.log("fileurl",fileurl);
          // this.fileurl = fileurl;
          // let audioElement = new Audio(fileurl);
          // let duration;
          // audioElement.addEventListener("loadedmetadata", function (_event) {
          //   duration = audioElement.duration;
          //   console.log("视频时长:" + duration);
          // });
        }
      },
   },
}
</script>
<style  lang="scss" scoped>
.makeCon ::v-deep .el-dialog__header {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.popDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 30px 48px;
  border-radius: 2px;
  box-sizing: border-box;
  .shijian {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #F6F7FB;
    width: 420px;
    // height: 136px;
    padding: 10px 0;
    margin: 0 0 40px;
  }
  .midaudio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .audioimg {
      width: 120px;
      height: 120x;
      margin-bottom: 20px;
    }
    .refresh {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .btnzu {
    width: 100%;
    margin-top: 48px;
    div {
      width: 200px;
      height: 60px;
      line-height: 60px;
      border-radius: 2px;
      text-align: center;
      color: #fff;
      background: $color-title2;
      box-sizing: border-box;
    }
    .btn_border {
      border: 1px solid #5D6581;
      color: #5D6581!important;
      background: none!important;
    }
  }
  .confirm {
    width: 526px;
    height: 99px;
    line-height: 99px;
    text-align: center;
    border-radius: 60px;
    background: $color-title2;
    color: #fff;
    font-weight: bold;
  }
}
</style>
